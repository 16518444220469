import type { Vendor } from '@repo/db/schema'
import { Icon } from '@repo/ui/components/Icon.js'
import { cn } from '@repo/ui/utils/utils'
import type { ComponentProps } from 'react'
import { Form } from '~/components/Form'
import { useForm } from '~/hooks/useForm'
import { useOptimisticValue } from '~/hooks/useOptimisticValue'
import { ToggleFavoriteVendorSchema } from './routeConfig'

export const FavoriteButton = ({
  isFavorite,
  vendorId,
  size = 'md'
}: {
  isFavorite: boolean
  vendorId: Vendor['id']
  size?: ComponentProps<typeof Icon>['size']
}) => {
  const fetcherKey = `${ToggleFavoriteVendorSchema.shape.intent.value}_${vendorId}`

  const optimisticIsFavorite = useOptimisticValue({
    fetcherKey,
    key: 'toggleFavorite',
    defaultValue: isFavorite,
    schema: ToggleFavoriteVendorSchema.shape.toggleFavorite
  })

  const form = useForm({
    fetcherKey,
    action: '/api/favorites',
    schema: ToggleFavoriteVendorSchema,
    include: {
      vendorId,
      toggleFavorite: !optimisticIsFavorite
    }
  })

  return (
    <Form {...form} className="contents">
      <button type="submit">
        <Icon
          name={optimisticIsFavorite ? 'star-solid' : 'star-empty'}
          className={cn(
            optimisticIsFavorite ? 'text-amber-500' : 'text-muted-foreground',
            'hover:scale-105 transition-all duration-300'
          )}
          size={size}
        />
      </button>
    </Form>
  )
}
